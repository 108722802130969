import Styles from "@/components/SearchForm/components/InputProps.module.scss";
import FilterIcon from "./FilterIcon";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { setClearAllFormData, setFormType } from "@/store/slices/filterSlice";
import { Autocomplete, TextField } from "@mui/material";

const InputProps = ({
  loader,
  type,
  autoComplete,
  label,
  placeholder,
  messages,
  required,
  options,
  advanceSearchIcons,
  valueitem,
  onHandleMenuOpen,
  onChangeUpdate,
  is3view,
  isResetButton,
  resetLabel,
  name,
}) => {
  const dispatch = useDispatch();
  const updateOptionAccordingTypeKey = (array) => {
    return array;
  };
  return (
    <div
      className={`${
        is3view
          ? type === "submit"
            ? "col-xl-6 col-lg-12 col-md-12 col-12"
            : "col-xl-6 col-lg-6 col-md-6 col-12"
          : type === "submit"
          ? `col-xl-4 col-lg-6 col-md-6 col-12`
          : `col-xl-4 col-lg-6 col-md-6 col-12`
      } ${Styles.colP15}`}
    >
      <div className={`row justify-cotent-center ${Styles.rowBox}`}>
        {type === "text" ? (
          autoComplete ? (
            <Autocomplete
              disablePortal
              options={options}
              freeSolo
              onInputChange={(e) => onChangeUpdate(e.target.value)}
              aria-label={label}
              aria-required={required ? "true" : "false"}
              renderInput={(params) => (
                <div className={`form-group ${Styles.formGroup}`}>
                  <label
                    className={Styles.label}
                    htmlFor={name}
                  >
                    {label}
                    {required ? "*" : null}
                  </label>
                  <TextField
                    {...params}
                    name={name}
                    value={valueitem}
                    variant="outlined"
                    className={Styles.miField}
                    aria-label={label}
                    aria-required={required ? "true" : "false"}
                  />
                  <span
                    className={Styles.messages}
                    role="presentation"
                  >
                    {messages}&nbsp;
                  </span>
                </div>
              )}
            />
          ) : (
            <div className={`form-group ${Styles.formGroup}`}>
              <label
                className={Styles.label}
                htmlFor={name}
              >
                {label}
                {required ? "*" : null}
              </label>
              <input
                className={Styles.field}
                name={name}
                type={type}
                placeholder={placeholder}
                value={valueitem}
                onChange={(e) => {
                  onChangeUpdate(e.target.value);
                }}
                required={required}
                aria-required={required ? "true" : "false"}
                aria-label={label}
              />
              <span
                className={Styles.messages}
                role="presentation"
              >
                {messages}&nbsp;
              </span>
            </div>
          )
        ) : type === "select" ? (
          <div className={`form-group ${Styles.formGroup}`}>
            <label className={Styles.label}>
              {label}
              {required ? "*" : null}
            </label>
            <ReactSelect
              id={name}
              name={name}
              isLoading={loader}
              onMenuOpen={(e) => {
                onHandleMenuOpen("");
              }}
              className={Styles.field}
              type={type}
              value={
                updateOptionAccordingTypeKey(options)?.find(
                  (n) => n?.value === valueitem,
                )
                  ? updateOptionAccordingTypeKey(options)?.find(
                      (n) => n?.value === valueitem,
                    )
                  : ""
              }
              onInputChange={(e) => {
                if (e) {
                  onChangeUpdate(e);
                }
              }}
              onChange={(e) => {
                if (e) {
                  onChangeUpdate(e?.value ? e?.value : "");
                }
              }}
              onKeyDown={(e) => {
                if (e) {
                  onChangeUpdate(e?.target?.value);
                }
              }}
              required={required}
              options={updateOptionAccordingTypeKey(options)}
              isMulti={false}
              isSearchable={true}
              isClearable={false}
              placeholder={"Select"}
              maxMenuHeight={200}
              minMenuHeight={100}
            />
            <span
              className={Styles.messages}
              role="presentation"
            >
              {messages}&nbsp;
            </span>
          </div>
        ) : type === "submit" ? (
          <div className={`form-group ${Styles.formGroup2}`}>
            <button
              type="submit"
              className={Styles.submit}
            >
              {label}
            </button>
            {advanceSearchIcons ? (
              <button
                type="button"
                className={Styles.filterIcon}
                title="Advance Filter"
                onClick={(e) => {
                  dispatch(setFormType("advancefillter"));
                  dispatch(setClearAllFormData());
                }}
              >
                <FilterIcon />
              </button>
            ) : null}
            {isResetButton ? (
              <button
                type="reset"
                onClick={(e) => {
                  dispatch(setClearAllFormData());
                }}
                className={Styles.submit22}
                title="Clear Filter"
              >
                {resetLabel}
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InputProps;
